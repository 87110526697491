* {
    box-sizing: border-box;
}

/*
black 12, 3, 4
brown 100,73,54
l orange 249, 161,71
orange 198, 93,2
rorange 176, 33 ,1
*/
  
body {
    background: #2B2E4D;
    /* background: #131418; */
    margin: 20px;
    text-align: center;
}

.landing-page-container {
    width: 100%;
    height: 100vh;
}

h1, h2, h3, p, span {
    color: white;
    /* color: rgb(198, 93, 2) */
}

.card {
    background-color: #191B2D;
    border-color: #191B2D; 
    /* background-color: rgb(12, 3, 4);
    border-color: rgb(12, 3, 4); */
    border-width: 1px;
    border-style: solid;
    border-radius: 15px;
    max-width: 500px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 10px;
}

.description-header {
    box-shadow: 0 0 0 4px white , 0 0 0 0;
    /* box-shadow: 0 0 0 4px rgb(176, 33, 1), 0 0 0 0; */
    margin-top: 0px;
    max-width: 100%;
    background-color: #161928;
    /* background-color: rgb(12, 3, 4);; */
    border-radius: 0px;
    border-bottom: #161928;
    /* border-bottom: rgb(12, 3, 4);; */
    border-bottom-style: solid;
    border-bottom-width: 10px;  
}

#header-headline-1 {
    font-size: 5vh;
}

#header-text-1 {
    font-size: 2.5vh;
}

#header-text-2 {
    font-size: 2vh;
}

.timer {
    box-shadow: 0 0 0 4px white , 0 0 0 0;
    /* box-shadow: 0 0 0 4px rgb(176, 33, 1) , 0 0 0 0; */
}

.bigTimerCard {
    border-style: solid;
    border: 0px;
    border-bottom: 5px white solid;
    /* border-bottom: 5px rgb(176, 33, 1) solid; */
    margin: 10px;
}

.bigTimerLabelDiv {
    margin-top: 20px;
    margin-bottom: 0px;
    padding-top: 10px;
}

#bigTimerLabel {
    font-size: 4vh;
}

.bigTimer {
    font-size: 8.6vh;
    margin-top: 10px;
    margin-bottom: 60px;
}

.timerText1 {
    margin-bottom: 0px;
    font-size: 25px;   
}

.timerText2 {
    margin-bottom: 0px;
    font-size: 20px;   
}

.gameList {
    box-shadow: 0 0 0 4px white , 0 0 0 7px #1F56A0;
    /* box-shadow: 0 0 0 4px rgb(176, 33, 1) , 0 0 0 7px rgb(25, 36, 51); */
    background-color: #191B2D;
    /* background-color: rgb(12, 3, 4); */
    max-width: 500px;
    margin: auto;
    border-radius: 10px;
}

.middleBreak {
    height: 75px;
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 20px;
    border-radius: 10px;
    text-align: center;
}
