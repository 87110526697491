
.gameTable {
    text-align: center;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10px;
}

.gameCard {
    margin-bottom: 5px;
}

.userTime {
    margin-bottom: 0px;
}

.middleRow {
    border-bottom: 2px white dotted;
    /* border-bottom: 2px rgb(198, 93, 2) dotted; */
}